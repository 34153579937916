<template>
  <div class="container-fluid shipping-address">
    <div class="row">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h5 class="shipping-address-header">
                  Order information
                </h5>
              </div>
              <div class="col text-end step-buttons">
                <button
                  @click="navigateBack()"
                  class="btn btn-light btn-sm me-2">
                  <i class="fa fa-arrow-left me-1"></i> Back
                </button>
                <button
                  @click="navigateForward()"
                  class="btn btn-outline-primary btn-sm"
                  :disabled="!cartSize || !newOrderTitle || !selectedShippingAddress
                  || (selectedSupplier !== null && !selectedShippingMethod)"
                >
                  <i class="far fa-eye me-1"></i>Order overview
                </button>
              </div>
            </div>

            <hr class="mb-2" />

            <div class="row my-4">
              <div class="col-4">
                <label class="mb-2">Order title *</label>
                <input
                  type="text"
                  v-model="newOrderTitle"
                  class="form-control form-control-sm mb-4"
                  placeholder="Order title"
                  required
                />
                <label class="mb-2">Order description</label>
                <textarea
                  v-model="newOrderDescription"
                  class="form-control form-control-sm"
                  placeholder="Order description"
                ></textarea>
              </div>
            </div>

            <!-- <div class="row mb-4">
              <div class="col-4">
                <label class="mb-2">Internal tracking reference</label>
                <input
                  type="text"
                  v-model="newInternalTrackingRef"
                  class="form-control form-control-sm"
                  placeholder="eg. UNI-438-977"
                />
              </div>
            </div> -->

            <div class="followUp">
              <div class="row mb-4">
                <div class="col-4">
                  <label class="mb-2">
                    Follow-up date <small>(optional)</small>
                  </label>

                  <datepicker
                    v-model="newFollowUpDate"
                    class="form-control form-control-sm"
                    placeholder="Set follow-up date (optional)"
                  ></datepicker>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-6">
                <div class="form-check">
                  <input
                    type="checkbox"
                    v-model="newIsHistoric"
                    class="form-check-input"
                  />
                  <label class="form-check-label">
                    Mark as historic order (paid / completed)?
                  </label>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-4">
                <label class="mb-2">
                  Send email confirmation to <small>(optional)</small>
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="confirmationMailTo"
                    value="client"
                    id="clientSendMailToCheck"
                    @change="eventConfirmationMailSelected($event)"
                  />
                  <label class="form-check-label">Client</label
                  >
                </div>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="confirmationMailTo"
                    value="practitioner"
                    id="meSendMailToCheck"
                    @change="eventConfirmationMailSelected($event)"
                  />
                  <label class="form-check-label">Me</label
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <hr class="mb-2" />
              </div>
            </div>

            <div class="row my-4">
              <div class="col-4">
                <h5>
                  Shipping address
                </h5>
              </div>
            </div>

            <div class="row my-4">
              <div class="col-4">
                <div v-if="client">
                  <label class="mb-2">Select a shipping address</label>
                  <select v-model="selectedShippingAddress" class="form-control form-control-sm">
                    <option v-if="!client.addresses.length">No addresses found</option>
                    <option v-for="(address, index) in client.addresses" :key="index" :value="address">
                      <span class="text-capitalize">{{ address.label }} / {{ address.one_line }}</span>
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-4">
                <button
                  @click="showCreateShippingAddressModal = !showCreateShippingAddressModal"
                  class="btn btn-light btn-sm"
                >
                  <i class="far fa-plus me-2"></i>Add new shipping address
                </button>
              </div>
            </div>

            <div class="row my-4" v-if="selectedSupplier !== null">
              <div class="col-4">
                <div v-if="client">
                  <label class="mb-2">Select a shipping method</label>
                  <select
                    v-model="selectedShippingMethod"
                    class="form-control form-control-sm"
                    :disabled="!selectedShippingAddress || !shippingMethods || shippingMethods.length < 1"
                  >
                    <option v-for="(method, index) in shippingMethods" :key="index" :value="method">
                      <span class="text-capitalize">{{ method.title }}</span>
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <hr class="mb-2" />
              </div>
            </div>

            <div class="row">
              <div class="col mx-2">
                <span class="total-items">{{ cartSize }} Items</span>
              </div>

              <div class="col mx-2 text-end">
                <span class="total-price">
                  Total:
                  {{ getCurrencySymbol() }}{{ cartTotal.toFixed(2) }}</span>
              </div>
            </div>

            <create-shipping-address
              v-if="showCreateShippingAddressModal"
              :client="client"
              @newShippingAddress="eventShippingAddressAddNew"
            ></create-shipping-address>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CreateShippingAddress from "./CreateShippingAddress";
  import Datepicker from "vuejs-datepicker";

  export default {
    props: [
      "cart",
      "cartSize",
      "cartTotal",
      "client",
      "followUpDate",
      "internalTrackingRef",
      "isHistoric",
      "orderDescription",
      "orderTitle",
      "selectedAddress",
      "selectedShipping",
      "selectedSupplier",
      "sendConfirmationMailTo",
      "shippingMethods"
    ],
    data() {
      return {
        confirmationMailTo: this.sendConfirmationMailTo,
        currency: null,
        currencySymbol: null,
        newFollowUpDate: this.followUpDate,
        newInternalTrackingRef: this.internalTrackingRef,
        newIsHistoric: this.isHistoric,
        newOrderDescription: this.orderDescription,
        newOrderTitle: this.orderTitle,
        selectedShippingAddress: this.selectedAddress,
        selectedShippingMethod: this.selectedShipping,
        showCreateShippingAddressModal: false
      };
    },
    methods: {
      eventConfirmationMailSelected(e) {
        this.$emit("selectMailRecipients", this.confirmationMailTo);
      },
      eventShippingAddressAddNew(address) {
        this.client.addresses.push(address);
        this.selectedShippingAddress = address;
      },

      getCurrencySymbol() {
        return this.cart[0] && this.cart[0].currency
          ? this.cart[0].currency.symbol : this.$stash.currencies[0].symbol;
      },

      navigateBack() {
        // Always force a shipping reset if we go back to adjust the cart
        // so the shipment method price is recalculated.
        this.$emit("eventShippingAddressSelected", null);
        this.$emit("eventShippingMethodSelected", null);
        this.$emit("navigateBack");
      },
      navigateForward() {
        this.$emit("navigateForward");
      },
    },
    mounted() {
      $("#create-shipping-address-modal").on("hidden.bs.modal", e => {
        this.showCreateShippingAddressModal = false;
      });
    },
    watch: {
      newFollowUpDate(date) {
        this.$emit("eventFollowUpDateChanged", date);
      },
      newInternalTrackingRef(ref) {
        this.$emit("eventInternalTrackingRefChanged", ref);
      },
      newIsHistoric(historic) {
        this.$emit("eventIsHistoricChanged", historic)
      },
      newOrderTitle(title) {
        this.$emit("eventOrderTitleChanged", title);
      },
      newOrderDescription(description) {
        this.$emit("eventOrderDescriptionChanged", description);
      },
      selectedShippingAddress(address) {
        this.$emit("eventShippingAddressSelected", address);
      },
      selectedShippingMethod(method) {
        this.$emit("eventShippingMethodSelected", method);
      }
    },
    components: {
      CreateShippingAddress,
      Datepicker
    }
  };
</script>

<style>
  .shipping-address-header {
    font-size: 1.125rem;
  }

  .total-items, .total-price {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .vdp-datepicker input {
    width: 100%;
  }

  @media (max-width: 768px) {
    .step-buttons {
      flex-basis: unset;
    }

    .col-4 {
      flex: unset;
      width: 100%;
    }

    .btn-light, .btn-outline-primary,
    .pagination > .col > button {
      width: 100%;
    }

    .btn-outline-primary,
    .pagination > .col > button {
      margin-top: 0.5rem;
    }
  }
</style>