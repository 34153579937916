<template>
  <div class="container-fluid supplier-select">
    <div class="row">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h5 class="supplier-select-header">
                  Select a supplier
                </h5>
              </div>
            </div>

            <hr class="mb-0" />

            <div class="row" v-if="!loading">
              <div class="col">

                <section class="grid">
                  <div v-for="supplier in suppliers" v-bind:key="supplier.id">
                    <div v-if="isAllowedSupplier(supplier)" class="supplier-card mt-4">
                      <div class="supplier-image">
                        <img
                        v-bind:src="supplier.photo_url"
                        class="supplier-list-image rounded-circle" />
                      </div>
                      <div class="supplier-info">
                        <div class="col text-center">
                          <h5 class="mb-0 text-primary font-weight-bold mt-3">
                            <i v-if="supplier.verified" class="fa fa-check-circle text-primary"></i>
                            {{ supplier.name }}
                          </h5>
                          <p class="mb-1 bg-light mt-3 p-2">{{ supplier.description | truncate }}</p>
                          <button
                            @click="eventSupplierSelected(supplier)"
                            class="btn btn-sm btn-outline-primary my-3"
                          >
                            Choose
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="supplier-card mt-4">
                    <div class="supplier-image">
                      <img
                      v-bind:src="user.clinic.photo_url"
                      class="supplier-list-image rounded-circle" />
                    </div>
                    <div class="supplier-info">
                      <div class="col text-center">
                        <h5 class="mb-0 text-primary font-weight-bold mt-3">
                          {{ user.clinic.name }}
                        </h5>
                        <p class="mb-1 bg-light mt-3 p-2">
                          Choose products from your clinic inventory.
                        </p>
                        <button
                          @click="eventSupplierSelected(null)"
                          class="btn btn-sm btn-outline-primary my-3 me-2"
                        >
                          Choose
                        </button>

                        <button
                          @click="navigateProductManagement()"
                          class="btn btn-sm btn-outline-primary my-3"
                        >
                          Manage
                        </button>
                      </div>
                    </div>
                  </div>
                </section>

              </div>
            </div>

            <loading v-if="loading" class="my-4"></loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from "@/views/components/loader/Loading";

  export default {
    props: ["cachedSuppliers"],
    data() {
      return {
        loading: true,
        suppliers: this.cachedSuppliers
      }
    },
    methods: {
      eventSupplierSelected(supplier) {
        this.$emit("eventSupplierSelected", supplier);
      },

      fetchSuppliers() {
        this.$axios.get(process.env.VUE_APP_API_URL + "/suppliers/api")
          .then(({ data }) => {
            this.suppliers = data;
            this.loading = false;

            this.$emit("cacheSuppliers", this.suppliers);
          }
        );
      },

      // We don't distinguish between account levels yet, but we do need a
      // linked email to order here (@todo make usable for multi-supplier).
      isAllowedSupplier(supplier) {
        return (supplier.verified && supplier.active) && this.$store.getters['auth/user'].nd_account_email;
      },

      navigateProductManagement() {
        this.$router.push('/settings/products');
      },
    },
    created() {
      if (this.suppliers.length > 0) {
        this.loading = false;
      } else {
        this.fetchSuppliers();
      }
    },
    computed: {
      user() {
        return this.$store.getters['auth/user'];
      },
    },
    filters: {
      truncate(text) {
        if (text != null) {
          if (text.length > 250) {
            return text.substring(0, 250) + "...";
          } else if (text.length == 0) {
            return "No description provided";
          } else {
            return text;
          }
        } else {
          return "No description provided";
        }
      }
    },
    components: {
      Loading
    }
  }
</script>

<style scoped>
  .supplier-select-header {
    font-size: 1.125rem;
  }

  .table thead > tr > th {
    border-top: 0px;
    border-bottom: 1px;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
  }

  .supplier-card {
    padding: 2%;
    flex-grow: 1;
    flex-basis: 16%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    max-width: 350px;
    box-shadow: 0 0.125rem 0.25rem rgb(55 67 67 / 8%) !important;
    margin-right: 2%;
  }

  .supplier-image img {
    width: 150px;
    height: 150px;
    max-width: 100%;
    margin-bottom: 10px;
    border: 1px solid #eee;
  }

  .supplier-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>