<template>
  <div>
    <div class="modal fade" role="dialog" id="productDetailsModal">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document" id="productDetailsModalDialog">
        <div class="modal-content bg-white" id="productDetailsModalContent">
          <div class="modal-body">

            <div class="flex items-center p-10 bg-white">
              <div class="grid">
                <div class="d-flex justify-content-center ms-3 me-3 py-4 desktop-col">
                  <div>
                    <img v-if="productDetails.featured_image" v-bind:src="productDetails.featured_image" class="product-image" >
                    <img v-else src="/products/no_image.png" class="product-image" >
                  </div>
                </div>
                <div class="flex col-desktop-col">
                  <div class="p-4">
                    <div class="mb-4">
                      <h5>
                        {{ productDetails.title }}
                      </h5>
                      <h5>
                        <price-element :product="productDetails"></price-element>
                      </h5>
                    </div>

                    <!-- @todo Make this into a little nicer (supplier-specific?) component -->
                    <div class="row mb-4">
                      <div class="col-4">
                        <span v-if="productDetails.brand">
                          Brand:
                        </span>
                        <br v-if="productDetails.brand && productDetails.sku" />
                        <span v-if="productDetails.sku">
                          SKU:
                        </span>
                      </div>
                      <div class="col">
                        <span v-if="productDetails.brand">
                          {{ productDetails.brand }}
                        </span>
                        <br v-if="productDetails.brand && productDetails.sku"/>
                        <span v-if="productDetails.sku">
                          {{ productDetails.sku }}
                        </span>
                      </div>
                    </div>

                    <div v-if="productDetails.description" class="bg-light p-2 mb-4">
                      {{ productDetails.description | truncateHtml }}
                    </div>

                    <div class="mb-4">
                      <div class="row">
                        <div class="col add-to-order">
                          <input
                            type="number"
                            v-model="quantity"
                            class="form-control form-control-sm me-2"
                            style="width:100px"
                          >
                          <button
                            @click="productAddToCart(productDetails)"
                            class="btn btn-light mt-2"
                          >
                            <i class="far fa-plus me-1"></i> Add to order
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex col modal-close-button text-end">
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PriceElement from "./PriceElement";

  export default {
    props: ["productDetails"],
    data() {
      return {
        productImage: null,
        quantity: 1
      }
    },
    methods: {
      productAddToCart(product) {
        if (this.quantity <= 0) {
          return;
        }

        this.$emit(
          "productAddToCart",
          { "product": product, "quantity": this.quantity }
        );
      },
    },
    filters: {
      truncateHtml(str) {
        var tmp = document.createElement("textarea");
        tmp.innerHTML = str;
        str = tmp.value;

        if (str.length > 500) {
          return str.substring(0, 500) + "...";
        } else {
          return str;
        }
      }
    },
    components: {
      PriceElement
    }
  }
</script>

<style scoped>
  .modal-body {
    padding: 0.5rem 1rem;
  }

  .close {
    border: 0px;
    background-color: #fff;
    font-size: 2rem;
  }

  .grid {
    display: flex;
  }

  .product-image {
    height: 200px;
    margin-bottom: 10px;
  }

  .add-to-order {
    display: flex;
    align-items: baseline;
  }

  @media (max-width: 768px) {
    .grid {
      flex-wrap: wrap;
    }

    .col-desktop-col {
      flex: unset;
      width: 100%;
    }

    .modal-close-button {
      position: absolute;
      right: 10px;
    }
  }
</style>