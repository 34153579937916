<template>
  <div class="container-fluid order-overview">
    <div class="row">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h5 class="order-overview-header">
                  Order overview
                </h5>
              </div>
              <div class="col text-end step-buttons">
                <button
                  @click="navigateBack()"
                  class="btn btn-light btn-sm me-2">
                  <i class="fa fa-arrow-left me-1"></i> Back
                </button>
                <button
                  @click="orderComplete()"
                  class="btn btn-outline-primary btn-sm"
                  :disabled="!cart.length || disableSubmit"
                >
                  <i class="fa fa-check me-1"></i> Complete
                </button>
              </div>
            </div>

            <hr class="mb-2" />

            <div class="row mb-4" v-if="!loading">
              <div class="col">
                <table v-if="cart.length" class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index) in cart" :key="index">
                      <td>
                        <p class="mb-0">{{ product.title }}</p>
                        <small v-if="!isShippingMethod(product)">
                          <i>
                            {{ product.brand ? product.brand : "" }}
                            {{ product.brand && product.supplier ? " / " : ""}}
                            {{ product.supplier ? product.supplier.name : "" }}
                          </i>
                        </small>
                      </td>
                      <td>
                        {{ getCurrencySymbol() }}{{
                          product.retail_price ? parseFloat(product.retail_price).toFixed(2)
                          : parseFloat(product.price).toFixed(2)
                        }}
                      </td>
                      <td>
                        {{ product.quantity }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="!cart.length" class="my-2">
                  No products found
                </div>
              </div>
            </div>

            <div class="row mb-1 subtotal" v-if="!loading">
              <div class="col mx-2">
                <p>
                  <b>Subtotal</b>
                </p>
              </div>

              <div class="col mx-2 text-end">
                <p>
                  <b>{{ getCurrencySymbol() }}{{ subtotal.toFixed(2) }}</b>
                </p>
              </div>
            </div>

            <div v-if="!loading && cartTax" class="row mb-1">
              <div class="col mx-2">
                <p>Taxes</p>
              </div>

              <div class="col mx-2 text-end">
                <p>
                  {{ getCurrencySymbol() }}{{ cartTax.toFixed(2) }}
                </p>
              </div>
            </div>

            <div v-if="!loading && selectedDiscount" class="row">
              <div class="col mx-2">
                <p>Discount {{ selectedDiscount.code }}</p>
              </div>

              <div class="col mx-2 text-end">
                <p>
                  - {{ getCurrencySymbol() }}{{ cartDiscount.toFixed(2) }}
                </p>
              </div>
            </div>

            <div class="row" v-if="!loading">
              <div class="col">
                <hr class="mb-2" />
              </div>
            </div>

            <div class="row" v-if="!loading">
              <div class="col mx-2">
                <span class="total-items">{{ cartSize }} Items</span>
              </div>

              <div class="col mx-2 text-end">
                <span class="total-price">
                  Total:
                  {{ getCurrencySymbol() }}{{ cartTotal.toFixed(2) }}</span>
              </div>
            </div>

            <loading v-if="loading" class="my-4"></loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from "@/views/components/loader/Loading";

  export default {
    props: [
      "cart",
      "cartDiscount",
      "cartSize",
      "cartTax",
      "cartTotal",
      "selectedDiscount"
    ],
    data() {
      return {
        currency: null,
        currencySymbol: null,
        disableSubmit: false,
        loading: false,
        subtotal: 0,
      }
    },
    methods: {
      calculateSubtotal() {
        this.cart.forEach((product) => {
          this.subtotal = this.subtotal + parseFloat(product.retail_price ?? product.price);
        });
      },

      getCurrencySymbol() {
        return this.cart[0] && this.cart[0].currency
          ? this.cart[0].currency.symbol : this.$stash.currencies[0].symbol;
      },

      isShippingMethod(product) {
        return product.model_type == 'App\\Models\\ProductTypeShippingMethod';
      },

      navigateBack() {
        this.$emit("navigateBack");
      },

      orderComplete() {
        this.disableSubmit = true;
        this.loading = true;

        this.$emit("orderComplete");
      },
    },
    mounted() {
      this.calculateSubtotal();
    },
    components: {
      Loading
    }
  }
</script>

<style scoped>
  .order-overview-header {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
  }

  .table thead > tr > th {
    border-top: 0px;
    border-bottom: 1px;
  }

  .subtotal {
    margin-top: 4rem;
  }

  .total-items, .total-price {
    font-size: 1.1rem;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .step-buttons {
      flex-basis: unset;
    }

    .desktop-col {
      display: none;
    }

    .btn-light, .btn-outline-primary,
    .pagination > .col > button {
      width: 100%;
    }

    .btn-outline-primary,
    .pagination > .col > button {
      margin-top: 0.5rem;
    }
  }
</style>