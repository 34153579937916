<template>
  <div class="price-element">
    <span v-if="product.discounted_price" class="ms-2">
      {{ getCurrencySymbol() }}{{ parseFloat(product.discounted_price).toFixed(2) }}
    </span>
    <span v-else>
      {{ getCurrencySymbol() }}{{ product.price ? parseFloat(product.price).toFixed(2) : '' }}
    </span>
  </div>
</template>

<script>
  export default {
    props: ["product"],
    data() {
      return {}
    },
    methods: {
      getCurrencySymbol() {
        return this.product.currency
          ? this.product.currency.symbol : this.$stash.currencies[0].symbol;
      },
    }
  }
</script>

<style>
</style>