<template>
  <div class="container-fluid product-search">
    <div class="row">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h5 class="product-search-header">Search products</h5>
              </div>
              <div class="col text-end step-buttons">
                <button
                  @click="navigateBack()"
                  class="btn btn-light btn-sm me-2">
                  <i class="fa fa-arrow-left me-1"></i> Back
                </button>

                <button
                  @click="navigateForward()"
                  @mouseover="cartShowContent"
                  @mouseleave="cartHideContents"
                  class="btn btn-outline-primary btn-sm"
                  :disabled="cartSize == 0"
                >
                  <i class="fa fa-shopping-cart me-1"></i> Checkout ({{ cartSize }})
                </button>
                <div id="cart-items" class="dropdown-menu dropdown-menu-right cart-items p-4">
                  <h5 class="cart-header">In cart</h5>

                  <hr class="my-2" />

                  <table v-if="cart.length" class="table table-hover mb-4 cart-hover">
                    <thead>
                      <tr>
                        <th style="padding: 0.75rem 0;">Product</th>
                        <th style="padding: 0.75rem 0;">Price</th>
                        <th style="padding: 0.75rem 0;">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="product in cart" :key="product.id">
                        <td style="padding: 0.75rem 0;">{{ product.title | truncate(20) }}</td>
                        <td style="padding: 0.75rem 0;">
                          {{ getCurrencySymbol() }}{{
                            product.override_price
                            ? parseFloat(product.override_price).toFixed(2)
                            : (
                              product.discounted_price
                              ? parseFloat(product.discounted_price).toFixed(2)
                              : parseFloat(product.price).toFixed(2)
                            )
                          }}
                        </td>
                        <td style="padding: 0.75rem 0;">{{ product.quantity }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="!cart.length" class="mb-4">
                    No products found
                  </div>

                  <h5>
                    Total:
                    {{ getCurrencySymbol() }}{{ parseFloat(cartTotal - cartTax).toFixed(2) }}
                  </h5>
                </div>
              </div>
            </div>

            <hr class="mb-0" />

            <div class="row">
              <div class="col-auto my-auto d-flex align-items-center form-inline">
                <select
                  class="form-control form-control-sm me-2"
                  v-model="searchField"
                >
                  <option value="title">Title</option>
                  <option value="sku">SKU</option>
                  <option value="category">Category</option>
                </select>
                <input
                  type="text"
                  v-model="searchTerm"
                  @input="search"
                  class="form-control form-control-sm me-2"
                  autocomplete="on"
                  placeholder="Enter search query"
                />

                <select
                  v-if="selectedSupplier === null"
                  class="form-control form-control-sm"
                  v-model="clinicProductType"
                >
                  <option value="custom">Custom products</option>
                  <option value="clinic_supplement">Supplements</option>
                  <option value="clinic_test">Tests</option>
                </select>
              </div>

              <div class="col-auto ms-auto d-flex align-items-center form-inline">
                <div class="d-flex m-4">
                  <button
                    @click="productDisplaySwitchView()"
                    class="btn btn-sm btn-outline-dark me-1 cursor-pointer"
                    :class="gridView ? 'active' : ''"
                  >
                    <i class="fas fa-th"></i>
                  </button>
                  <button
                    @click="productDisplaySwitchView()"
                    class="btn btn-sm btn-outline-dark me-1 cursor-pointer"
                    :class="!gridView ? 'active' : ''"
                  >
                    <i class="fas fa-bars"></i>
                  </button>
                </div>
                <div class="d-flex my-4">
                  <button
                    class="btn btn-sm btn-outline-dark me-1 cursor-pointer"
                    :class="sortDirection != 'desc' ? 'active' : ''"
                    @click="productDisplaySortDir('asc')"
                  >
                    <i class="far fa-sort-size-up"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-outline-dark me-2 cursor-pointer"
                    :class="sortDirection == 'desc' ? 'active' : ''"
                    @click="productDisplaySortDir('desc')"
                  >
                    <i class="far fa-sort-size-down"></i>
                  </button>
                </div>
                <div class="dropdown">
                  <button
                    class="btn btn-light btn-sm dropdown-toggle"
                    type="button"
                    id="sortingDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="far fa-sort me-2"></i>
                    <span class="text-capitalize">{{ sortField | formatLabel }}</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sortingDropdown">
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="productDisplaySortBy('title')"
                    >
                      Title
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="productDisplaySortBy('sku')"
                    >
                      SKU
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="productDisplaySortBy('category')"
                    >
                      Category
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="productDisplaySortBy('price')"
                    >
                      Price
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="productDisplaySortBy('created_at')"
                    >
                      Created At
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4" v-if="!loading">
              <div class="col">

                <section class="grid mb-2" v-if="gridView">
                  <div v-for="product in products" v-bind:key="product.id" class="grid-card">
                    <div @click="productDetailsModalOpen(product)" class="grid-image">
                      <img v-if="product.featured_image" v-bind:src="product.featured_image" class="grid-list-image" />
                      <img v-else src="/products/no_image.png" class="grid-list-image" />
                    </div>
                    <div class="grid-info">
                      <span class="title mt-1">{{ product.title | truncate(40) }}</span>
                      <span class="price mt-1">
                        <price-element :product="product"></price-element>
                      </span>
                      <button
                        @click="productAddToCart(product)"
                        class="btn btn-light btn-sm mt-2"
                      >
                        <i class="far fa-plus me-1"></i> Add to order
                      </button>

                      <button
                        v-if="productGetQuantityInCart(product) > 0"
                        @click="productRemoveFromCart(product)"
                        class="btn btn-light btn-sm mt-2"
                      >
                        <i class="far fa-minus me-1"></i> Remove ({{ productGetQuantityInCart(product) }})
                      </button>
                    </div>
                  </div>
                  <div v-if="!products.length" class="my-2">
                    No products found
                  </div>
                </section>

                <section class="list mb-4" v-else>
                  <table v-if="products.length" class="table table-hover">
                    <thead>
                      <tr>
                        <th class="desktop-col">SKU</th>
                        <th>Title</th>
                        <th class="desktop-col">Category</th>
                        <th>Price</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(product) in products" :key="product.id">
                        <td @click="productDetailsModalOpen(product)" class="desktop-col">{{ product.sku }}</td>
                        <td @click="productDetailsModalOpen(product)">{{ product.title | truncate(40) }}</td>
                        <td @click="productDetailsModalOpen(product)" class="desktop-col">{{ product.category_title ? product.category_title : '-' }}</td>
                        <td @click="productDetailsModalOpen(product)">{{ getCurrencySymbol() }}{{ parseFloat(product.price).toFixed(2) }}</td>
                        <td class="text-end" style="display: flex; flex-direction: row; align-items: center; justify-content: end;">
                          <button
                            @click="productAddToCart(product)"
                            class="btn btn-light btn-sm me-1"
                          >
                            <i class="far fa-plus me-1"></i>
                            <span class="desktop-col">Add to order</span>
                          </button>

                          <button
                            v-if="productGetQuantityInCart(product)"
                            @click="productRemoveFromCart(product)"
                            class="btn btn-light btn-sm"
                          >
                            <i class="far fa-minus me-1"></i>
                            <span class="desktop-col">Remove ({{ productGetQuantityInCart(product) }})</span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="!products.length" class="my-2">
                    No products found
                  </div>
                </section>

              </div>
            </div>

            <loading v-else class="mb-4"></loading>

            <div class="row pagination">
              <div class="col">
                <button
                  @click="fetchProducts(pagination.first_page_url)"
                  :disabled="!pagination || !pagination.first_page_url
                    || pagination.current_page == 1"
                  class="btn btn-light btn-sm me-3"
                >
                  First Page
                </button>
                <button
                  @click="fetchProducts(pagination.prev_page_url)"
                  :disabled="!pagination || !pagination.prev_page_url"
                  class="btn btn-light btn-sm"
                >
                  Previous Page
                </button>
              </div>
              <div class="col text-center my-auto">
                <i class="small">
                  Page {{ pagination ? pagination.current_page : 1 }}
                  of
                  {{ pagination ? pagination.last_page: 1 }}
                </i>
              </div>
              <div class="col text-end">
                <button
                  @click="fetchProducts(pagination.next_page_url)"
                  :disabled="!pagination || !pagination.next_page_url"
                  class="btn btn-light btn-sm me-3"
                >
                  Next Page
                </button>
                <button
                  @click="fetchProducts(pagination.last_page_url)"
                  :disabled="!pagination || !pagination.last_page_url
                    || pagination.current_page == pagination.last_page"
                  class="btn btn-light btn-sm"
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <product-details-modal
      @productAddToCart="productAddToCartFromModal"
      :productDetails="productDetails"
    ></product-details-modal>
  </div>
</template>

<script>
  import debounce from "debounce";
  import Loading from "@/views/components/loader/Loading";
  import PriceElement from "./PriceElement";
  import ProductDetailsModal from "./ProductDetailsModal";

  export default {
    props: [
      "cachedProducts",
      "cart",
      "cartSize",
      "cartTax",
      "cartTotal",
      "selectedSupplier"
    ],
    data() {
      return {
        categories: [],
        clinicProductType: "custom",
        currency: null,
        gridView: true,
        loading: true,
        pagination: null,
        products: this.cachedProducts,
        productDetails: "",
        searchField: "title",
        searchParam: "",
        searchTerm: "",
        sortDirection: "asc",
        sortField: "title",
      }
    },
    methods: {
      // Mouseovers on checkout button.
      cartHideContents() {
        let list = document.getElementById('cart-items');
        list.style="display: none;";
      },
      cartShowContent() {
        let list = document.getElementById('cart-items');
        list.style="display: block;";
      },

      getCurrencySymbol() {
        return this.cart[0] && this.cart[0].currency
          ? this.cart[0].currency.symbol : this.$stash.currencies[0].symbol;
      },

      navigateBack() {
        this.$emit("navigateBack");
      },
      navigateForward() {
        this.$emit("navigateForward");
      },

      productAddToCart(product) {
        this.$emit("productAddToCart", product);
      },
      productAddToCartFromModal(data) {
        for (let i = 0; i < data.quantity; i++) {
          this.productAddToCart(data.product);
        }
        $("#productDetailsModal").modal("hide");
      },
      productDetailsModalOpen(product) {
        this.productDetails = product;
        $("#productDetailsModal").modal('show');
      },
      productGetQuantityInCart(product) {
        let existingProduct = this.cart.find(
          x => (x.id === product.id && x.model_type === product.model_type)
        );
        return existingProduct && existingProduct.quantity ? existingProduct.quantity : 0;
      },
      productDisplaySortBy(field) {
        this.sortField = field;
        this.fetchProducts();
      },
      productDisplaySortDir(direction) {
        this.sortDirection = direction;
        this.fetchProducts();
      },
      productDisplaySwitchView() {
        this.gridView = !this.gridView;
        localStorage.setItem('productDisplayListView', !this.gridView);
      },
      productRemoveFromCart(product) {
        this.$emit("productRemoveFromCart", product);
      },

      fetchProducts: debounce(function(paginationUrl) {
        let url = process.env.VUE_APP_API_URL + "/products";

        if (paginationUrl) {
          url = paginationUrl;
        } else {
          let endpoint = '/custom';
          if (this.selectedSupplier) {
            endpoint = '/' + this.selectedSupplier.name.replace(' ', '_').toLowerCase();
          }
          if (!this.selectedSupplier) {
            endpoint = '/' + this.clinicProductType.toLowerCase();
          }
          url += endpoint;
        }

        if (this.searchParam) {
          url = this.updateQueryStringParameter(url, "search_term", this.searchParam);
          url = this.updateQueryStringParameter(url, "search_field", this.searchField);
        }

        if (this.sortField) {
          url = this.updateQueryStringParameter(url, "sort_field", this.sortField);
        }
        if (this.sortDirection) {
          url = this.updateQueryStringParameter(url, "sort_direction", this.sortDirection);
        }

        this.loading = true;

        this.$axios.get(url).then(({ data }) => {
          this.pagination = data;
          this.products = data.data;
          this.loading = false;
          this.$emit("cacheProducts", this.products);
        });
      }, 300),

      search: debounce(function() {
        if (this.searchTerm.length > 2) {
          this.searchParam = this.searchTerm;
        } else {
          this.searchParam = "";
        }
        this.fetchProducts();
      }, 300),

      updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf("?") !== -1 ? "&" : "?";

        if (uri.match(re)) {
          return uri.replace(re, "$1" + key + "=" + value + "$2");
        } else {
          return uri + separator + key + "=" + value;
        }
      },

    },
    created() {
      this.gridView = localStorage.getItem('productDisplayListView') !== 'true';

      if (this.products.length > 0) {
        this.loading = false;
      } else {
        this.fetchProducts();
      }
    },
    mounted() {
      $("#productDetailsModal").on("hide.bs.modal", (e) => {
        this.$emit("modalClosed");
      });
    },
    filters: {
      formatLabel(str) {
        if (!str) {
          return "Sorting";
        } else {
          return str.replace(/_/g, " ");
        }
      },
      truncate(str, len) {
        if (str.length > len) {
          return str.substring(0, len) + "...";
        } else {
          return str;
        }
      },
    },
    watch: {
      clinicProductType() {
        this.fetchProducts();
      },
    },
    components: {
      Loading,
      PriceElement,
      ProductDetailsModal
    }
  }
</script>

<style scoped>
  .product-search-header, .cart-header {
    font-size: 1.125rem;
  }

  .cart-items {
    min-width: 450px;
    right: 0.8rem;
    z-index: 100;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
  }

  .table thead > tr > th {
    border-top: 0px;
    border-bottom: 1px;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
  }

  .grid-card {
    padding: 2%;
    flex-basis: 20%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }

  .grid-image img {
    height: 200px;
    max-width: 100%;
    margin-bottom: 10px;
    border: 1px solid #eee;
  }

  .grid-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .grid-info .title {
    font-size: 1rem;
    text-align: center;
  }

  .grid-info .price {
    font-size: 0.8rem;
    text-align: center;
  }

  @media (max-width: 768px) {
    .step-buttons {
      flex-basis: unset;
    }

    .grid-card {
      flex-basis: unset;
      width: 100%;
      margin: 0.5rem 0;
    }

    select, input {
      margin-top: 1rem;
    }

    .desktop-col {
      display: none;
    }

    .pagination {
      flex-direction: column;
    }

    .btn-light, .btn-outline-primary,
    .pagination > .col > button {
      width: 100%;
    }

    .btn-outline-primary,
    .pagination > .col > button {
      margin-top: 0.5rem;
    }
  }
</style>