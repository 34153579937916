<template>
  <div class="container-fluid create-custom-order">
    <supplier-select
      v-if="step == 0"
      @cacheSuppliers="cacheSuppliers"
      @eventSupplierSelected="eventSupplierSelected"
      :cachedSuppliers="cachedSuppliers"
    ></supplier-select>

    <product-search
      v-if="step == 1"
      @cacheProducts="cacheProducts"
      @navigateBack="navigateBack"
      @navigateForward="navigateForward"
      @productAddToCart="productAddToCart"
      @productRemoveFromCart="productRemoveFromCart"
      :cachedProducts="cachedProducts"
      :cart="cart"
      :cartSize="cartSize"
      :cartTax="cartTax"
      :cartTotal="cartTotal"
      :selectedSupplier="selectedSupplier"
    ></product-search>

    <order-edit
      v-if="step == 2"
      @cartUpdate="cartUpdate"
      @eventDiscountCodeSelected="eventDiscountCodeSelected"
      @navigateBack="navigateBack"
      @navigateForward="navigateForward"
      @productAddDirections="productAddDirections"
      @productAddToCart="productAddToCart"
      @productChangeOverridePrice= "productChangeOverridePrice"
      @productRemoveFromCart="productRemoveFromCart"
      :cart="cart"
      :cartDiscount="cartDiscount"
      :cartSize="cartSize"
      :cartTax="cartTax"
      :cartTotal="cartTotal"
      :productDirections="productDirections"
      :selectedDiscount="selectedDiscountCode"
      :selectedSupplier="selectedSupplier"
    ></order-edit>

    <shipping-address
      v-if="step == 3"
      @navigateBack="navigateBack"
      @navigateForward="navigateForward"
      @eventFollowUpDateChanged="eventFollowUpDateChanged"
      @eventInternalTrackingRefChanged="eventInternalTrackingRefChanged"
      @eventIsHistoricChanged="eventIsHistoricChanged"
      @eventMailRecipientsSelected="eventMailRecipientsSelected"
      @eventOrderDescriptionChanged="eventOrderDescriptionChanged"
      @eventOrderTitleChanged="eventOrderTitleChanged"
      @eventShippingAddressSelected="eventShippingAddressSelected"
      @eventShippingMethodSelected="eventShippingMethodSelected"
      :cart="cart"
      :cartSize="cartSize"
      :cartTax="cartTax"
      :cartTotal="cartTotal"
      :client="client"
      :followUpDate="followUpDate"
      :internalTrackingRef="internalTrackingRef"
      :isHistoric="isHistoric"
      :orderDescription="orderDescription"
      :orderTitle="orderTitle"
      :selectedAddress="selectedShippingAddress"
      :selectedShipping="selectedShippingMethod"
      :selectedSupplier="selectedSupplier"
      :sendConfirmationMailTo="sendConfirmationMailTo"
      :shippingMethods="shippingMethods"
    ></shipping-address>

    <order-overview
      v-if="step == 4"
      @navigateBack="navigateBack"
      @orderComplete="orderComplete"
      :cart="cart"
      :cartDiscount="cartDiscount"
      :cartSize="cartSize"
      :cartTax="cartTax"
      :cartTotal="cartTotal"
      :selectedDiscount="selectedDiscountCode"
    ></order-overview>
  </div>
</template>

<script>
  import debounce from "debounce";
  import Loading from "@/views/components/loader/Loading";
  import OrderEdit from "./custom-order-partials/OrderEdit";
  import OrderOverview from "./custom-order-partials/OrderOverview.vue";
  import ProductSearch from "./custom-order-partials/ProductSearch";
  import ShippingAddress from "./custom-order-partials/ShippingAddress";
  import SupplierSelect from "./custom-order-partials/SupplierSelect"

  export default {
    props: [],
    data() {
      return {
        cachedProducts: [],
        cachedSuppliers: [],
        cart: [],
        cartId: null,
        cartDiscount: 0,
        cartSize: 0,
        cartTax: 0,
        cartTotal: 0,
        client: null,
        followUpDate: null,
        internalTrackingRef: null,
        isHistoric: false,
        orderDescription: '',
        orderTitle: '',
        productDirections: [],
        selectedDiscountCode: null,
        selectedShippingAddress: null,
        selectedShippingMethod: null,
        selectedSupplier: null,
        sendConfirmationMailTo: ["client", "practitioner"],
        shippingMethods: [],
        step: 0
      }
    },
    methods: {
      // We cache these so they don't have to be retrieved
      // each back.
      cacheProducts(products) {
        this.cachedProducts = products;
      },
      cacheSuppliers(suppliers) {
        this.cachedSuppliers = suppliers;
      },

      // This is called on every cart change. It used a watcher
      // before but that caused loops, so it's manually called.
      cartCalculateTotals() {
        let cartSize = 0;
        let cartTax = 0;
        let cartTotal = 0;

        this.cart.forEach((product) => {
          cartSize = cartSize + product.quantity;

          if (!this.isShippingMethod(product) && this.isTaxable(product)) {
            cartTax = cartTax + parseFloat(product.retail_tax * product.quantity);
          }

          if (this.hasOverridePrice(product)) {
            cartTotal = cartTotal + parseFloat(product.override_price * product.quantity);
          } else if (this.hasDiscountedPrice(product)) {
            cartTotal = cartTotal + parseFloat(product.discounted_price * product.quantity);
          } else {
            cartTotal = cartTotal + parseFloat(product.price * product.quantity);
          }
        });

        this.cartSize = cartSize;
        this.cartTax = cartTax;
        this.cartTotal = cartTotal + cartTax;

        // @todo Check if this should be discount before or after tax.
        // It won't matter either way for ND but..
        if (this.selectedDiscountCode) {
          this.cartDiscount = ((this.cartTotal/100) * this.selectedDiscountCode.discount_percent);
          this.cartTotal = this.cartTotal - this.cartDiscount;
        }
      },
      cartCreate() {
        this.$axios.post(
          process.env.VUE_APP_API_URL + "/cart",
          {
            client_id: this.client.id,
          }
        ).then(({ data }) => {
          if (data.color === 'danger') {
            this.$EventBus.$emit("alert", data);
          }

          this.cartId = data.cart.id;
        });
      },
      cartRestore(cartId) {
        this.$axios.get(
          process.env.VUE_APP_API_URL + "/cart/" + cartId
        ).then(({ data }) => {
          if (data.color == 'danger') {
            this.$EventBus.$emit("alert", data);
          }

          this.cartId = data.cart.id;
          let cartItems = [];
          if (data.cart.cart_items.length > 0) {
            data.cart.cart_items.forEach((product) => {
              // We don't want to restore shipping methods, always recalc.
              if (!this.isShippingMethod(product)) {
                cartItems.push(product);
              }
            });

            this.cart = cartItems;
            this.selectedSupplier = data.cart.supplier ? data.cart.supplier : null;
            this.step = 1;
          }

          this.cartCalculateTotals();
        });
      },
      cartUpdate: debounce(function(orderedCart = null) {
        if (orderedCart) {
          this.cart = orderedCart;
        }

        this.cartCalculateTotals();

        if (this.cartId) {
          this.$axios.put(
            process.env.VUE_APP_API_URL + "/cart/" + this.cartId,
            {
              client_id: this.client.id,
              supplier_id: this.selectedSupplier ? this.selectedSupplier.id : null,
              total: this.cartTotal,
              quantity: this.cartSize,
              items: this.cart
            }
          ).then(({ data }) => {
            if (data.color == 'danger') {
              this.$EventBus.$emit("alert", data);
            }
          });
        }
      }, 300),

      eventDiscountCodeSelected(code) {
        this.selectedDiscountCode = code;
        this.cartCalculateTotals();
      },
      eventFollowUpDateChanged(date) {
        this.followUpDate = date;
      },
      eventInternalTrackingRefChanged(ref) {
        this.internalTrackingRef = ref;
      },
      eventIsHistoricChanged(historic) {
        this.isHistoric = historic;
      },
      eventMailRecipientsSelected(recipients) {
        this.sendConfirmationMailTo = recipients;
      },
      eventOrderDescriptionChanged(description) {
        this.orderDescription = description;
      },
      eventOrderTitleChanged(title) {
        this.orderTitle = title;
      },
      eventShippingAddressSelected(shippingAddress) {
        this.selectedShippingAddress = shippingAddress;

        // Don't trigger for clinic orders.
        if (shippingAddress && this.selectedSupplier !== null) {
          this.$axios.post(
            process.env.VUE_APP_API_URL + "/cart/" + this.cartId + "/calculate-shipping",
            { address: this.selectedShippingAddress }
          ).then(({ data }) => {
            if (data.color == 'danger') {
              this.$EventBus.$emit("alert", data);
            }

            this.shippingMethods = data.methods;
          });
        }
      },
      eventShippingMethodSelected(shippingMethod = null) {
        let products = [];
        this.cart.forEach((product) => {
          // Remove previous shipping method if in one exists.
          if (!this.isShippingMethod(product)) {
            products.push(product);
          }
        });

        this.cart = products;
        this.selectedShippingMethod = shippingMethod;

        if (shippingMethod) {
          this.productAddToCart(shippingMethod);
        } else {
          // When we go back from shipping, the shipping method
          // is set to null and we have to recalculate.
          this.cartUpdate();
        }
      },
      eventSupplierSelected(supplier) {
        this.selectedSupplier = supplier;

        // Reset some stuff.
        this.cachedProducts = [];
        this.cart = [];
        this.cartDiscount = 0;
        this.cartSize = 0;
        this.cartTotal = 0;
        this.selectedDiscountCode = null;
        this.step = 1;

        // We are an existing cart.
        if (this.cartId) {
          // Supplier null is the own clinic.
          let requestParams = {};
          if (supplier !== null) {
            requestParams = { params: { supplier_id: supplier.id }}
          }

          // Empty cart (we don't allow multi-supplier carts
          // at this time).
          this.$axios.get(
            process.env.VUE_APP_API_URL + "/cart/" + this.cartId + "/empty",
            requestParams
          );
        }
      },

      // Either retrieve the existing cart, or create a new one.
      // If it has a supplier, skip the selection screen.
      fetchCart() {
          let cartId = this.$route.params.cartId;

          if (cartId !== undefined) {
            this.cartRestore(cartId);
          } else {
            this.cartCreate();
          }
      },
      fetchClient() {
        return this.$axios.get(
          process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
        );
      },

      hasDiscountedPrice(product) {
        return product.discounted_price != undefined && product.discounted_price != "" && product.discounted_price != 0;
      },
      hasOverridePrice(product) {
        return product.override_price != undefined && product.override_price != "" && product.override_price != 0;
      },
      isShippingMethod(product) {
        return product.model_type == 'App\\Models\\ProductTypeShippingMethod';
      },
      isTaxable(product) {
        if (!product.supplier_id && !product.clinic_tax_id) {
          return false;
        }

        return product.vat_status !== 'exempt';
      },

      navigateBack() {
        if (this.step > 0) {
          this.step = this.step - 1;
        }
      },
      navigateForward() {
        this.step = this.step + 1;
      },

      orderComplete() {
        this.$axios.post(
          process.env.VUE_APP_API_URL + "/cart/" + this.cartId + "/convert-to-order",
          {
            address: this.selectedShippingAddress,
            client_id: this.client.id,
            currency_id: this.cart[0].currency ? this.cart[0].currency.id : 1,
            discount_code: this.selectedDiscountCode,
            follow_up_date: this.followUpDate,
            fulfillment: this.selectedSupplier !== null ? 'supplier' : 'practitioner',
            historic: this.isHistoric,
            order_description: this.orderDescription,
            order_title: this.orderTitle,
            send_mail_to: this.sendConfirmationMailTo,
            tracking: this.internalTrackingRef
          }
        ).then(({ data }) => {
          this.$EventBus.$emit("alert", data);

          if (data.color != 'danger') {
            this.cartId = null;
            window.location = '/clients/' + this.client.id + '/orders';
          }
        });
      },

      // @todo There's a case to be made these methods should be
      // named cartAddProductEtc.
      productAddDirections(product) {
        this.cart[this.cart.indexOf(product)] = product;
      },
      productAddToCart(product) {
        let productCount = 1;

        product = this.productCalculateCostVsRetail(product);

        this.cart.forEach((cartProduct) => {
          if (cartProduct.id === product.id
            && cartProduct.model_type === product.model_type) {
            productCount = cartProduct.quantity + 1;
            this.cart.splice(this.cart.indexOf(cartProduct), 1);
          }
        });

        product.quantity = productCount;
        this.cart.push(product);

        this.cartUpdate();
      },
      // Cost is either discounted_price or price. Retail is
      // override_price or cost.
      productCalculateCostVsRetail(product) {
        if (this.isShippingMethod(product)) {
          return product;
        }

        if (this.hasDiscountedPrice(product)) {
          product.cost_price = product.discounted_price;
        }

        if (!product.cost_price) {
          product.cost_price = product.price;
        }

        product.retail_price = this.hasOverridePrice(product) ? product.override_price : product.cost_price;
        product.cost_tax = this.isTaxable(product) ? this.productCalculateIncludedTax(product.cost_price, product.tax_rate) : 0;
        product.retail_tax = this.isTaxable(product) ? this.productCalculateIncludedTax(product.retail_price, product.tax_rate) : 0;

        return product;
      },
      productCalculateIncludedTax(price, rate) {
        rate = (rate ?? 20) / 100;
        return (price * rate) / (1 + rate);

      },
      productChangeOverridePrice(product) {
        let products = [];

        product = this.productCalculateCostVsRetail(product);

        this.cart.forEach((cartProduct) => {
          if (cartProduct.id === product.id
            && cartProduct.model_type === product.model_type) {
            cartProduct.override_price = product.override_price;
          }

          products.push(cartProduct);
        });

        this.cart = products;
        this.cartUpdate();
      },
      productRemoveFromCart(product) {
        let products = [];

        this.cart.forEach((cartProduct) => {
          if (cartProduct.id === product.id
            && cartProduct.model_type === product.model_type) {
            cartProduct.quantity = cartProduct.quantity - 1;
          }

          if (cartProduct.quantity > 0) {
            products.push(cartProduct);
          }
        });

        this.cart = products;
        this.cartUpdate();
      },
    },
    created() {
      this.fetchClient().then(({ data }) => {
        this.client = data.client;
        this.eventOrderTitleChanged("Order for client #" + this.client.id);
        this.fetchCart();
      });
    },
    components: {
      Loading,
      OrderEdit,
      OrderOverview,
      ProductSearch,
      ShippingAddress,
      SupplierSelect
    },
  };
</script>

<style scoped>
  @media (max-width: 768px) {
    .container-fluid {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
</style>
