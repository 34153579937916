<template>
  <div>
    <div v-if="productDetails" class="modal fade" role="dialog" id="productDirectionsModal">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document" id="productDirectionsModalDialog">
        <div class="modal-content bg-white" id="productDirectionsModalContent">

          <div class="modal-header">
            <h5 class="modal-title">
              {{ productDetails.title }}
            </h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div v-if="productDetails.directions" class="mb-4">
              Supplier directions:
              <i
                class="far fa-info-circle mx-2 text-primary"
                v-tooltip:bottom="'The supplier instructions are not shown to the client.'"
              ></i>
              <br/>
              <div class="bg-light p-2 mt-2">
                {{ productDetails.directions }}
              </div>
            </div>

            <div class="mb-4">
              Client directions: <br/>
              <div class="mt-2">
                <textarea
                  class="form-control"
                  placeholder="Enter your directions for this product..."
                  v-model="productDetails.comments"></textarea>
              </div>
            </div>

            <div class="mb-4">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="followUpCheckbox"
                    @change="eventFollowUpCheckboxChange()"
                  />
                  <label class="form-check-label">
                    Set reorder reminder
                  </label>
                </div>
            </div>

            <div v-if="followUpCheckbox && productHasNumericPackageQty()" class="mb-4">
              Package contains: <br/>
              <div class="mt-2">
                {{ productDetails.package_qty }} {{ productDetails.package_unit }}
              </div>
            </div>

            <div v-if="followUpCheckbox && productHasNumericPackageQty() && productDetails.serving_instructions" class="mb-4">
              Recommended daily use: <br/>
              <div class="bg-light p-2 mt-2 mb-4">
                {{ productDetails.serving_instructions }}
              </div>
            </div>

            <div v-if="followUpCheckbox && productHasNumericPackageQty()" class="mb-4">
              Client daily use: <br/>
              <div class="mt-2">
                <input
                  type="text"
                  v-model="dosageQty"
                  @focus="$event.target.select()"
                /> {{ productDetails.package_unit }}
              </div>
            </div>

            <div v-if="followUpCheckbox" class="mb-4">
              Reminder date: <br/>
              <div class="mt-2">
                  <datepicker
                    v-model="followUpDate"
                    class="form-control form-control-sm"
                    placeholder="Set reorder reminder"
                  ></datepicker>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm me-auto"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm"
              @click="productAddDirections"
            >
              Save
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Datepicker from "vuejs-datepicker";

  export default {
    props: ["productDetails", "productDirections"],
    data() {
      return {
        dosageQty: 0,
        followUpCheckbox: false,
        followUpDate: null
      }
    },
    methods: {
      eventFollowUpCheckboxChange() {
        this.followUpCheckbox = this.followUpCheckbox === true ? true : false;
      },
      productAddDirections() {
        // Save dosage and follow_up_date on item.
        let product = this.productDetails;

        if (!product.hasOwnProperty('comments')) {
          Object.assign(product, {
            comments: this.productDetails.comments
          });
        } else {
          product.comments = this.productDetails.comments;
        }

        if (this.followUpDate) {
          if (!product.hasOwnProperty('follow_up_date')) {
            Object.assign(product, {
              follow_up_date: this.followUpDate
            });
          } else {
            product.follow_up_date = this.followUpDate;
          }
        }

        this.dosageQty = 0;
        this.followUpCheckbox = !this.followUpCheckbox;
        this.followUpDate = null;

        this.$emit("productAddDirections", product);
      },

      productCalculateFollowUp() {
        let days = Math.floor(this.productDetails.package_qty / this.dosageQty);

        if (this.productDetails && this.productDetails.quantity > 1) {
          days = days * this.productDetails.quantity;
        }

        if (parseInt(this.dosageQty) > 0) {
          this.followUpDate = moment().add(days, 'days').toDate();
        } else {
          this.followUpDate = null;
        }
      },

      productHasNumericPackageQty() {
        return this.productDetails.package_qty && /^\d+$/.test(this.productDetails.package_qty);
      }
    },
    watch: {
      dosageQty() {
        this.productCalculateFollowUp();
      }
    },
    components: {
      Datepicker
    }
  }
</script>

<style scoped>
  .modal-body {
    padding: 0.5rem 1rem;
  }

  input[type="text"] {
    box-sizing: border-box;
    padding-left: 10px;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
  }

  .close {
    border: 0px;
    background-color: #fff;
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    .btn-light, .btn-outline-primary,
    .btn-outline-secondary {
      width: 100%;
    }
  }
</style>