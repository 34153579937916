<template>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="create-shipping-address-modal"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content bg-white">
        <div class="modal-header">
          <h5 class="modal-title">
            Create Shipping Address for {{ client.name }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body mb-4">
          <form @change="checkAddressCanBeSaved">
            <div class="row mb-3">
              <div class="col">
                <label for>Address Title*</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="new-password"
                  v-model="newAddressTitle"
                  required
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for>Address 1*</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="new-password"
                  v-model="newAddress1"
                  required
                />
              </div>
              <div class="col">
                <label for>Address 2</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="new-password"
                  v-model="newAddress2"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <label for>Town</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="new-password"
                  v-model="newAddressTown"
                />
              </div>
              <div class="col">
                <label for>City*</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="new-password"
                  v-model="newAddressCity"
                  required
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <label for>County</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="new-password"
                  v-model="newAddressCounty"
                />
              </div>
              <div class="col">
                <label for>Post Code*</label>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="new-password"
                  v-model="newAddressPostCode"
                  required
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for>Country*</label>
                <country-select v-model="newAddressCountry"></country-select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary btn-sm me-auto"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            @click="createShippingAddress"
            :disabled="!canBeSaved"
          >
            Create Shipping Address
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CountrySelect from "../../../globals/CountrySelect";

  export default {
    props: ["client"],
    data() {
      return {
        newAddressTitle: "",
        newAddress1: "",
        newAddress2: "",
        newAddressTown: "",
        newAddressCity: "",
        newAddressCounty: "",
        newAddressPostCode: "",
        newAddressCountry: "United Kingdom",
        canBeSaved: false,
      };
    },
    methods: {
      checkAddressCanBeSaved() {
        if (
          this.newAddressTitle != "" &&
          this.newAddress1 != "" &&
          this.newAddressCity != "" &&
          this.newAddressPostCode != "" &&
          this.newAddressCountry != ""
        ) {
          this.canBeSaved = true;
        } else {
          this.canBeSaved = false;
        }
      },
      createShippingAddress() {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/add-address",
            {
              label: this.newAddressTitle,
              address_1: this.newAddress1,
              address_2: this.newAddress2,
              town: this.newAddressTown,
              city: this.newAddressCity,
              county: this.newAddressCounty,
              post_code: this.newAddressPostCode,
              country: this.newAddressCountry,
              client_id: this.client.id,
              type: "shipping",
            }
          )
          .then(({ data }) => {
            this.$emit("newShippingAddress", data.address);
            this.$EventBus.$emit("alert", data);
            $("#create-shipping-address-modal").modal("hide");
          });
      },
    },
    mounted() {
      $("#create-shipping-address-modal").modal("show");
    },
    components: {
      CountrySelect,
    },
  };
</script>

<style>
</style>
