<template>
  <div class="container-fluid order-overview">
    <div class="row">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h5 class="order-edit-header">
                  Order edit
                  <!-- <i
                    class="far fa-info-circle mx-2 text-primary float-end"
                    v-tooltip:bottom="'Prices may be overridden.'"
                  ></i> -->
                </h5>
              </div>
              <div class="col text-end step-buttons">
                <button
                  @click="navigateBack()"
                  class="btn btn-light btn-sm me-2">
                  <i class="fa fa-arrow-left me-1"></i> Back
                </button>
                <button
                  @click="navigateForward()"
                  class="btn btn-outline-primary btn-sm"
                  :disabled="!cart.length"
                >
                  <i class="fa fa-shipping-fast me-1"></i> Shipping
                </button>
              </div>
            </div>

            <hr class="mb-2" />

            <div class="row mb-4" v-if="!loading">
              <div class="col">
                <table v-if="cart.length" class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Amount</th>
                      <th class="desktop-col">Custom Price</th>
                      <th class="desktop-col"></th>
                    </tr>
                  </thead>
                  <draggable v-model="orderedCart" @change="cartUpdate" tag="tbody">
                    <tr v-for="(product, index) in orderedCart" :key="index">
                      <td>
                        <p class="mb-0">{{ product.title | truncate(40) }}</p>
                        <small v-if="!isShippingMethod(product)">
                          <i>
                            {{ product.brand ? product.brand : "" }}
                            {{ product.brand && product.supplier ? " / " : ""}}
                            {{ product.supplier ? product.supplier.name : "" }}
                          </i>
                        </small>
                      </td>
                      <td>
                        {{ getCurrencySymbol() }}{{ product.cost_price ? parseFloat(product.cost_price).toFixed(2) : parseFloat(product.price).toFixed(2) }}
                        <br />
                        <i v-if="product.cost_tax && product.vat_status !== 'exempt'" class="small">
                          Excluding {{ getCurrencySymbol() }}{{ parseFloat(product.cost_tax).toFixed(2) }} {{ product.tax_name }} at {{ product.tax_rate ? product.tax_rate : 20 }}%
                        </i>
                      </td>
                      <td>
                        <input
                          v-if="!isShippingMethod(product)"
                          type="number"
                          min="0"
                          step="1"
                          :value="product.quantity"
                          @input="productChangeQuantity(product, $event)"
                          class="form-control form-control-sm"
                          placeholder="Amount"
                        />
                        <span v-else>-</span>
                      </td>
                      <td class="desktop-col">
                        <input
                          v-if="!isCustomProduct(product) && !isShippingMethod(product)"
                          type="number"
                          min="0"
                          step="0.01"
                          v-model="product.override_price"
                          @input="productChangeOverridePrice(product)"
                          class="form-control form-control-sm"
                          placeholder="Override Price"
                        />
                        <span v-else>-</span>
                        <i v-if="product.retail_tax && product.vat_status !== 'exempt' && product.override_price" class="small">
                          Excluding {{ getCurrencySymbol() }}{{ parseFloat(product.retail_tax).toFixed(2) }} {{ product.tax_name }} at {{ product.tax_rate ? product.tax_rate : 20 }}%
                        </i>
                      </td>
                      <td class="text-end desktop-col">
                        <button
                          v-if="!isShippingMethod(product)"
                          class="btn btn-sm btn-light me-1"
                          title="Add directions"
                          @click="productDirectionsModalOpen(product)"
                        >
                          <i class="far fa-edit"></i>
                        </button>

                        <button
                          v-if="!isShippingMethod(product)"
                          class="btn btn-sm btn-light"
                          @click="productRemoveAllFromCart(product)"
                        >
                          <i class="far fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </draggable>
                </table>
                <div v-if="!cart.length" class="my-2">
                  No products found
                </div>
              </div>
            </div>

            <div class="row mb-3 discount">
              <div v-if="this.selectedSupplier !== null" class="col-4 mx-2">
                  <label class="mb-2">
                    <small>Discount code</small>
                  </label>
                  <select v-model="selectedDiscountCode" class="form-control form-control-sm">
                    <option v-if="!discountCodes.length">No codes found</option>
                    <option v-if="discountCodes.length" :value="null">No discount</option>
                    <option v-for="(discount, index) in discountCodes" :key="index" :value="discount">
                      <span class="text-capitalize">{{ discount.code }} ({{ discount.discount_percent }}%)</span>
                    </option>
                  </select>
              </div>
            </div>

            <div v-if="this.selectedSupplier && this.selectedSupplier.id === 2" class="row">
              <div class="col-6 mx-2">
                <small>
                  <i>*Prices include Natural Dispensary practitioner discount</i>
                </small>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <hr class="mb-2" />
              </div>
            </div>

            <div class="row" v-if="!loading">
              <div class="col mx-2">
                <span class="total-items">{{ cartSize }} Items</span>
              </div>

              <div class="col mx-2 text-end">
                <span class="total-price">
                  Total:
                  {{ getCurrencySymbol() }}{{ cartTotal.toFixed(2) }}
                </span>
              </div>
            </div>

            <loading v-if="loading" class="my-4"></loading>
          </div>
        </div>
      </div>
    </div>

    <product-directions-modal
      @productAddDirections="productAddDirectionsFromModal"
      :productDetails="productDetails"
      :productDirections="productDirections"
    ></product-directions-modal>

  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import Loading from "@/views/components/loader/Loading";
  import ProductDirectionsModal from "./ProductDirectionsModal.vue";

  export default {
    props: [
      "cart",
      "cartSize",
      "cartTax",
      "cartTotal",
      "productDirections",
      "selectedDiscount",
      "selectedSupplier"
    ],
    data() {
      return {
        currency: null,
        currencySymbol: null,
        discountCodes: [],
        dragging: false,
        loading: false,
        // We use a separate, ordered cart on this screen because
        // EA requested she'd be able to edit invoice appearance
        // order of the items.
        orderedCart: this.cart,
        productDetails: null,
        selectedDiscountCode: this.selectedDiscount
      }
    },
    methods: {
      cartUpdate() {
        this.$emit("cartUpdate", this.orderedCart);
      },

      fetchDiscountCodes() {
        if (this.selectedSupplier !== null) {
          this.$axios
            .get(
              process.env.VUE_APP_API_URL + "/settings/discount-codes/get-discount-codes",
              { params: { supplier_id: this.selectedSupplier.id }}
            )
            .then(({ data }) => {
              this.discountCodes = data;
            });
        }
      },

      getCurrencySymbol() {
        return this.cart[0] && this.cart[0].currency
          ? this.cart[0].currency.symbol : this.$stash.currencies[0].symbol;
      },

      isCustomProduct(product) {
        return product.model_type == 'App\\Models\\ProductTypeCustomProduct';
      },
      isShippingMethod(product) {
        return product.model_type == 'App\\Models\\ProductTypeShippingMethod';
      },

      navigateBack() {
        this.$emit("navigateBack");
      },
      navigateForward() {
        this.$emit("navigateForward");
      },

      productAddDirectionsFromModal(product) {
        this.$emit("productAddDirections", product);

        $("#productDirectionsModal").modal("hide");
      },
      productChangeOverridePrice(product) {
        this.$emit("productChangeOverridePrice", product);
      },
      productChangeQuantity(originalProduct, changedProduct) {
        if (changedProduct.target.value && originalProduct.quantity
          && changedProduct.target.value > originalProduct.quantity) {
          this.$emit("productAddToCart", originalProduct);
        } else {
          if (changedProduct.target.value == 0) {
            // Remove interface row.
            this.productRemoveAllFromCart(originalProduct);
          } else {
            // Remove 1 instance of product.
            this.$emit("productRemoveFromCart", originalProduct);
          }
        }
      },
      productDirectionsModalOpen(product) {
        this.productDetails = product;
        $("#productDirectionsModal").modal('show');
      },
      productRemoveAllFromCart(product) {
        let index = this.orderedCart.indexOf(product);
        this.orderedCart.splice(index, 1);

        for (let i = product.quantity; i > 0; i--) {
          this.$emit("productRemoveFromCart", product);
        }
      },
    },
    mounted() {
      this.fetchDiscountCodes();

      $("#productDirections").on("hide.bs.modal", (e) => {
        this.$emit("modalClosed");
      });
    },
    filters: {
      truncate(str, len) {
        if (str.length > len) {
          return str.substring(0, len) + "...";
        } else {
          return str;
        }
      },
    },
    watch: {
      selectedDiscountCode(code) {
        this.$emit("eventDiscountCodeSelected", code);
      },
    },
    components: {
      draggable,
      Loading,
      ProductDirectionsModal
    }
  }
</script>

<style scoped>
  .order-edit-header {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
  }

  .table thead > tr > th {
    border-top: 0px;
    border-bottom: 1px;
  }

  .discount {
    margin-top: 4rem;
  }

  .total-items, .total-price {
    font-size: 1.1rem;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .step-buttons {
      flex-basis: unset;
    }

    .col-4 {
      flex: unset;
      width: 100%;
    }

    .desktop-col {
      display: none;
    }

    .btn-light, .btn-outline-primary,
    .pagination > .col > button {
      width: 100%;
    }

    .btn-outline-primary,
    .pagination > .col > button {
      margin-top: 0.5rem;
    }
  }
</style>